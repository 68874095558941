module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='\n  <a class="info default-icon">User: \n    <span class="toggle"> \n      '+
((__t=(_.escape(username)))==null?'':__t)+
'\n    </span>\n    ';
 if (!autoLoginEnabled) {
__p+='\n      <i id="userLogoutIcon" class="fa fa-power-off" aria-hidden="true"></i>\n    ';
 }
__p+='\n  </a>\n  \n  <ul id="userInfo" class="subBarDropdown">\n    <li class="dropdown-header">\n      <div style="height: 1px; background: rgb(119, 203, 153)"></div>\n      <img class="user-img" src="'+
((__t=(img))==null?'':__t)+
'"/>\n        <p>';
 if (name) {
__p+=''+
((__t=(_.escape(username)))==null?'':__t)+
'<small> '+
((__t=(_.escape(name)))==null?'':__t)+
' </small>\n        ';
 } else {
__p+='\n          '+
((__t=(_.escape(username)))==null?'':__t)+
'\n        ';
 } 
__p+='\n        </p>\n    </li>\n    ';
 if (!autoLoginEnabled) {
__p+='\n      <li class="dropdown-footer">\n        <button id="userLogout" class="button-primary btn-small pull-right">Logout</button>\n      </li>\n    ';
 }
__p+='\n    ';
 if (autoLoginEnabled) {
__p+='\n      <li class="dropdown-footer" style="max-width: 200px; line-height: 1.4; padding: 0 10px !important;">\n        This login is automatically managed by ArangoGraph.\n      </li>\n    ';
 }
__p+='\n  </ul>\n  ';
}
return __p;
};
